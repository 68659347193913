import React from 'react';
import ReactDOM from 'react-dom';
import {Loader} from './components/atoms';
import SnackbarProvider from 'react-simple-snackbar';
import * as serviceWorker from './serviceWorker';

import './assets/scss/index.scss';
import 'rc-slider/assets/index.css';

// DayJS
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';

dayjs.extend(customParseFormat);

const App = React.lazy(() => import('./App'));

ReactDOM.render(
  <React.Suspense fallback={<Loader />}>
    <SnackbarProvider>
      <App />
    </SnackbarProvider>
  </React.Suspense>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
