import React, {useState, useCallback} from 'react';
import RCSlider from 'rc-slider';

const style = {
  handle: [
    {
      borderColor: 'white',
      backgroundColor: '#e3e829',
      boxShadow: 'none',
    },
  ],
  track: [
    {
      backgroundColor: '#e3e829',
    },
  ],
};

const Slider = ({
  containerClass,
  textClass,
  prefixClass,
  suffixClass,
  valueClass,
  prefix,
  suffix,
  onChange: oc,
  defaultValue = 0,
  short = false,
  min = 50,
  max = 250,
  step = 10,
}) => {
  const [value, setValue] = useState(defaultValue);

  const onChange = useCallback(
    (v) => {
      setValue(v);
      if (typeof oc === 'function') oc(v);
    },
    [oc, setValue],
  );

  return (
    <>
      <RCSlider
        defaultValue={defaultValue}
        trackStyle={style.track}
        handleStyle={style.handle}
        min={min}
        max={max}
        step={step}
        onChange={onChange}
      />
      {!short && (
        <div className={'d-flex ' + containerClass}>
          <span className={`${textClass} ${prefixClass}`}>{prefix}</span>
          <span className={`${textClass} ${valueClass}`}>{value}</span>
          <span className={`${textClass} ${suffixClass}`}>{suffix}</span>
        </div>
      )}
    </>
  );
};

export default Slider;
