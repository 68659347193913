import React, {useCallback, useState} from 'react';
import {Input as StrapInput} from 'reactstrap';
import Loader from '../loader';

const Input = ({
  error,
  errorClass,
  conatinerClass,
  className,
  onChangeText,
  onBlur,
  formatText,
  loading,
  placeholder,
  maxLength,
  disabled,
  value = '',
  type = 'text',
  inputMode = 'text',
}) => {
  const [v, setValue] = useState('');

  const onChange = useCallback(
    ({target}) => {
      let temp = target.value;
      if (typeof formatText === 'function') temp = formatText(temp);
      setValue(temp);
      if (typeof onChangeText === 'function') onChangeText(temp);
    },
    [onChangeText, formatText],
  );

  return (
    <>
      <div className={'c_input  position-relative ' + conatinerClass}>
        <StrapInput
          tabIndex={-1}
          type={type}
          onBlur={onBlur}
          disabled={disabled}
          onChange={onChange}
          inputMode={inputMode}
          maxLength={maxLength}
          placeholder={placeholder}
          value={value.length > 0 ? value : v}
          className={'input ' + className}
        />
        {loading && <Loader className="loading" size="sm" />}
      </div>
      {error && <small className={'text-danger ' + errorClass}>{error}</small>}
    </>
  );
};

const Alt = ({
  id,
  label,
  error,
  errorClass,
  conatinerClass,
  className,
  onChangeText,
  onBlur,
  formatText,
  loading,
  maxLength,
  disabled,
  placeholder,
  value = '',
  type = 'text',
  inputMode = 'text',
}) => {
  const [v, setValue] = useState('');

  const onChange = useCallback(
    ({target}) => {
      let temp = target.value;
      if (typeof formatText === 'function') temp = formatText(temp);
      setValue(temp);
      if (typeof onChangeText === 'function') onChangeText(temp);
    },
    [onChangeText, formatText],
  );

  return (
    <>
      <div className={'c_input position-relative ' + conatinerClass}>
        <div className="form-control input_alt" disabled={disabled}>
          {label && <div className="label py-1 border-bottom">{label}</div>}
          <input
            tabIndex={-1}
            id={id}
            type={type}
            onBlur={onBlur}
            disabled={disabled}
            onChange={onChange}
            inputMode={inputMode}
            maxLength={maxLength}
            placeholder={placeholder}
            value={value.length > 0 ? value : v}
            className={'px-0 form-control clear ' + className}
          />
          {loading && <Loader className="loading" size="sm" />}
          {error && <small className={'text-danger ' + errorClass}>{error}</small>}
        </div>
      </div>
    </>
  );
};

export default Object.assign(Input, {Alt});
